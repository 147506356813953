import { graphql } from 'gatsby';
import React from 'react';
import { ContentfulProject } from '../types/graphql-types';
import Layout from "../components/layout/page-layout";
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import FadeInWrapper from '../components/animated/fade-wrapper';
import * as styles from '../styles/modules/project.module.css';

/**
 * data from graphql query
 */
interface Props {
  data: {
    contentfulProject: ContentfulProject;
  };
}

/**
 * Creates a project page from a ContentfulProject object.
 * @param {Props} props  props from graphql query
 * @returns {TSX.Element} page
 */
const Project: React.FC<Props> = (props: Props): JSX.Element => {
  const project: ContentfulProject = props.data.contentfulProject;
  return (
    <Layout title={project.name as string} description={project.description?.description as string} image={project.headerImage?.publicUrl as string}>
      <div id="about" className="page">
        <FadeInWrapper>
        <div className="row">
          <div className="col">
            <GatsbyImage className="img-fluid img-rounded" image={project.headerImage?.gatsbyImageData} alt={`${project.headerImage?.description}`} />
          </div>
          <div className="col">
            <FadeInWrapper>
            <h2 className={styles.projectCardHeading}>{project.name}</h2>
            <p className={styles.projectCardDate}>{new Date(project.creationDate ?? project.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</p>
            <p>{project.description?.description}</p>
            {
              (project.liveUrl || project.projectUrl) &&
              <ul>
                {project.liveUrl && <li><a href={`${project.liveUrl}`}><FontAwesomeIcon icon={faStarOfLife} /> Live at {new URL(project.liveUrl).hostname}</a></li>}
                {project.projectUrl && <li><a href={`${project.projectUrl}`}><FontAwesomeIcon icon={faCode} /> Source code available on {new URL(project.projectUrl).hostname}</a></li>}
              </ul>
            }
            </FadeInWrapper>
          </div>
        </div>
        <div className="row col ">
          <div dangerouslySetInnerHTML={{ __html: project.body?.childMarkdownRemark.html }} />
        </div>
        </FadeInWrapper>
      </div>

    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulProject(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      projectUrl
      liveUrl
      creationDate
      createdAt
      headerImage {
        gatsbyImageData
        publicUrl
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default Project;
